<script>
import Layout from "../_layouts/main"
import PageHeader from "@/components/page-header"
import List from '../../components/partner/list'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List
  },
  data() {
    return {
      title: "",
      state: null
    }
  },
  created() {
    this.state = this.$route.params.state
  },
  mounted() {
    let title = '파트너&nbsp'
    if (this.state === 'block') {
      title += '<span class="badge bg-warning">' + this.$t('차단') + '</span>'
    } else if (this.state === 'unregister') {
      title += '<span class="badge bg-danger">' + this.$t('탈퇴') + '</span>'
    }
    this.title = title
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <List :state="state"/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
